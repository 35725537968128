.mat-column-id {
  max-width: 86px;
}

.mat-column-acoes {
  max-width: 150px;
}

@page{
 size: auto;
 margin: 0mm;
}

.css-impressao {
  -webkit-print-color-adjust: exact;
}


// LOTOFACIL
.fill-lotofacil-100 {
  fill: #dd7ac6;
}

.bg-lotofacil-100 {
  background: #dd7ac6;
}

.border-lotofacil-200 {
  border-color: #bb67a7;
}

.text-lotofacil-300 {
  color: #ac5096;
}

// megasena
.fill-megasena-100 {
  fill: #6befa3;
}

.bg-megasena-100 {
  background: #6befa3;
}

.border-megasena-200 {
  border-color: #54c081;
}

.text-megasena-300 {
  color: #349c60;
}

// QUINA
.fill-quina-100 {
  fill: #8666ef;
}

.bg-quina-100 {
  background: #8666ef;
}

.border-quina-200 {
  border-color: #6f54c7;
}

.text-quina-300 {
  color: #583bb6;
}

// LOTOMANIA
.fill-lotomania-100 {
  fill: #ffab64;
}

.bg-lotomania-100 {
  background: #ffab64;
}

.border-lotomania-200 {
  border-color: #d18b51;
}

.text-lotomania-300 {
  color: #c77a3c;
}

// duplasena
.fill-duplasena-100 {
  fill: #ec3b83;
}

.bg-duplasena-100 {
  background: #ec3b83;
}

.border-duplasena-200 {
  border-color: #c03069;
}

.text-duplasena-300 {
  color: #b91d5c;
}

// TIMEMANIA
.fill-timemania-100 {
  fill: #5aad7d;
}

.bg-timemania-100 {
  background: #5aad7d;
}

.border-timemania-200 {
  border-color: #3e7756;
}

.text-timemania-300 {
  color: #2f774d;
}


// diadesorte
.fill-diadesorte-100 {
  fill: #bfaf83;
}

.bg-diadesorte-100 {
  background: #bfaf83;
}

.border-diadesorte-200 {
  border-color: #887c5d;
}

.text-diadesorte-300 {
  color: #88784b;
}


// supersete
.fill-supersete-100 {
  fill: #7ce619;
}

.bg-supersete-100 {
  background: #7ce619;
}

.border-supersete-200 {
  border-color: #61b413;
}

.text-supersete-300 {
  color: #4fa003;
}

// CORES DAS CARTELAS
.bg-cartela {
  background: #FFFFCE;
}
